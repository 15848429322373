const releases = {
  'release-2025-0': [
    {
      type: 'pencil',
      key: 'SK-93',
      summary: 'Datenschutzerklärung überarbeitet.',
      version: '2025.0.0',
      date: '2025-03-11',
      status: 'check-square',
    },
  ],
  'release-2024-3': [
    {
      type: 'brain-circuit',
      key: 'SK-92',
      summary: 'Neuer Synapsen Artikel - „(S010) - Der beruhigende Effekt des Sudoku: Ein Konterpart zum hektischen Alltag”',
      version: '2024.3.15',
      date: '2024-07-21',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-91',
      summary: 'Neuer Synapsen Artikel - „(S009) - Die Wissenschaft hinter Sudoku: Wie das Rätsel dein Gehirn trainiert”',
      version: '2024.3.14',
      date: '2024-06-23',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-90',
      summary: 'Neuer Synapsen Artikel - „(S008) - Das „Dutch Miracle“: Ein Meisterwerk des Sudoku-Designs”',
      version: '2024.3.13',
      date: '2024-06-08',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-89',
      summary: 'Neuer Synapsen Artikel - „(S007) - Entdecke das Geheimnis des Phistomefel-Rings”',
      version: '2024.3.12',
      date: '2024-05-26',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-88',
      summary: 'Platzhalter für Werbeanzeigen überarbeitet.',
      version: '2024.3.12',
      date: '2024-05-26',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-87',
      summary: 'Neuer Synapsen Artikel - „(S006) - Sudoku als Brücke zwischen den Generationen”',
      version: '2024.3.11',
      date: '2024-05-16',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-86',
      summary: 'Neuer Synapsen Artikel - „(S005) - Warum das tägliche Lösen von Sudoku-Rätseln zu deinem Ritual werden sollte“',
      version: '2024.3.10',
      date: '2024-05-05',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-85',
      summary: 'Übersichtsseite „Synapsen” neu gestaltet.',
      version: '2024.3.10',
      date: '2024-05-05',
      status: 'check-square',
    },
    {
      type: 'graduation-cap',
      key: 'SK-84',
      summary: 'Neue Lektion „Der Versteckte Einer“ hinzugefügt.',
      version: '2024.3.9',
      date: '2024-05-01',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-83',
      summary: 'Fehler behoben: Weiter-Schaltfläche bei gelöstem Rätsel wird von Werbung überlagert.',
      version: '2024.3.8',
      date: '2024-04-22',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-82',
      summary: 'Neuer Synapsen Artikel - „(S004) - Die faszinierende Geschichte des Sudoku: Ursprung und globale Verbreitung“',
      version: '2024.3.7',
      date: '2024-04-21',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-81',
      summary: 'Platzhalter für Werbeanzeigen überarbeitet.',
      version: '2024.3.6',
      date: '2024-04-14',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-80',
      summary: 'Neuer Synapsen Artikel - „(S003) - Ein offenes Wort: Warum Werbung auf Sudoku Knacker unverzichtbar ist“',
      version: '2024.3.5',
      date: '2024-04-11',
      status: 'check-square',
    },
    {
      type: 'graduation-cap',
      key: 'SK-79',
      summary: 'Neue Lektion „Das Full House“ hinzugefügt.',
      version: '2024.3.4',
      date: '2024-04-10',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-78',
      summary: 'Platzhalter für Werbeanzeigen überarbeitet.',
      version: '2024.3.3',
      date: '2024-04-07',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-77',
      summary: 'Fehler behoben: Werbung wurde bei zahlenden Accounts angezeigt.',
      version: '2024.3.2',
      date: '2024-04-03',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-76',
      summary: 'Umstellung auf die Ezoic Werbeplattform.',
      version: '2024.3.1',
      date: '2024-04-03',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-75',
      summary: 'Neue Darstellung des Unterstützer:innen Slots.',
      version: '2024.3.1',
      date: '2024-04-03',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-74',
      summary: 'Aktuelles wird jetzt neben der Account Ansicht angezeigt.',
      version: '2024.3.1',
      date: '2024-04-03',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-73',
      summary: 'Neuer Synapsen Artikel - „(S002) - Deine Eintrittskarte in die Welt der Sudoku-Meister“',
      version: '2024.3.0',
      date: '2024-04-02',
      status: 'check-square',
    },
    {
      type: 'graduation-cap',
      key: 'SK-72',
      summary: 'Neuen Bereich „Akademie“ hinzugefügt.',
      version: '2024.3.0',
      date: '2024-04-02',
      status: 'check-square',
    },
  ],
  'release-2024-2': [
    {
      type: 'wand-sparkles',
      key: 'SK-71',
      summary:
        'Durch Klicken auf die neue Schaltfläche "Magisches Notizfeld" werden im Notizfeld des aktiven Eingabefeldes die noch möglichen Ziffern für dieses Feld automatisch eingetragen.',
      version: '2024.2.3',
      date: '2024-03-29',
      status: 'check-square',
    },
    {
      type: 'ad',
      key: 'SK-70',
      summary: 'Ezoic Werbeplattform integriert und Anzeigen-Placements bereitgestellt.',
      version: '2024.2.2',
      date: '2024-03-28',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-68',
      summary:
        'Neue Funktion: Neue Schaltfläche "Highlightmodus" hebt Zeile, Spalte und das 3x3-Unterquadrat eines aktiven Eingabefeldes hervor.',
      version: '2024.2.1',
      date: '2024-03-27',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-67',
      summary: 'Ezoic Datenschutzbestimmung und CMP hinzugefügt.',
      version: '2024.2.0',
      date: '2024-03-26',
      status: 'check-square',
    },
    {
      type: 'brain-circuit',
      key: 'SK-66',
      summary: 'Neuen Bereich „Synapsen“ hinzugefügt. Neuer Artikel: „Willkommen bei Synapsen: Dein regelmäßiges Sudoku-Brain-Gym!”',
      version: '2024.2.0',
      date: '2024-03-26',
      status: 'check-square',
    },
  ],
  'release-2024-1': [
    {
      type: 'pencil',
      key: 'SK-65',
      summary: 'Überarbeitung der Werbe-Slots für optimierte Bereitstellung von Werbemaßnahmen.',
      version: '2024.1.3',
      date: '2024-03-24',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-64',
      summary: 'Datenschutzerklärung wird jetzt auf einer einzelnen Seite vollständig angezeigt.',
      version: '2024.1.2',
      date: '2024-03-23',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-63',
      summary: 'Texte überarbeitet, korrigiert und aktualisiert.',
      version: '2024.1.1',
      date: '2024-03-23',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-62',
      summary: 'Optimierung der Werbebereitstellung und Cookie-Zustimmung, um Nutzererlebnis und Inhaltszugang zu verbessern.',
      version: '2024.1.0',
      date: '2024-03-23',
      status: 'check-square',
    },
  ],
  'release-2024-0': [
    {
      type: 'pencil',
      key: 'SK-61',
      summary: 'CMP auf DSGVO-konforme Mitteilung zur Einwilligung der Nutzer in der EU von Google umgestellt.',
      version: '2024.0.7',
      date: '2024-03-13',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-60',
      summary: 'Datenschutzerklärung für Google Adsense aktualisiert.',
      version: '2024.0.6',
      date: '2024-03-12',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-59',
      summary: 'Fehler behoben: Attribut für das Ausspielen von Werbeanzeigen korrigiert.',
      version: '2024.0.5',
      date: '2024-03-11',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-58',
      summary: 'Nicht personalisierte Werbung anzeigen, falls der Benutzer Targeting-Cookies widersprochen hat.',
      version: '2024.0.4',
      date: '2024-03-11',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-57',
      summary: 'Cookie-Schaltfläche zeigt nun an, ob das Cookie für das Targeting akzeptiert wurde oder nicht.',
      version: '2024.0.3',
      date: '2024-03-10',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-56',
      summary: 'Style-Anpassungen am Button für die CMP-Anzeige wurden durchgeführt, um das Design für alle Gerätegrößen zu optimieren.',
      version: '2024.0.2',
      date: '2024-02-18',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-55',
      summary:
        'Anpassungen am Button für die Anzeige der Consent Management Plattform (CMP) vorgenommen, um eine bessere Integration in das Gesamtdesign zu gewährleisten',
      version: '2024.0.1',
      date: '2024-02-18',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-54',
      summary:
        'Anpassungen gemäß dem Digital Markets Act (DMA) und der IAB TCF 2.2 Spezifikation, um die Einhaltung neuer regulatorischer Anforderungen zu gewährleisten',
      version: '2024.0.0',
      date: '2024-02-10',
      status: 'check-square',
    },
  ],
  'release-2023-0': [
    {
      type: 'pencil',
      key: 'SK-49',
      summary:
        'Es wurden kleine Änderungen vorgenommen, um Abhängigkeiten zu aktualisieren und kleine inhaltliche Korrekturen vorzunehmen.',
      version: '2023.0.0',
      date: '2023-02-19',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-50',
      summary: 'Anpassungen für Google-Analytics vorgenommen und die Telefonnummer im Impressum angepasst.',
      version: '2023.2.0',
      date: '2023-06-28',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-51',
      summary: 'Anpassungen vor Umzug auf neue Server-Plattform.',
      version: '2023.3.0',
      date: '2023-08-06',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-52',
      summary: 'Fehler behoben: Die Farben der Navigationslinks haben sich mit der Auswahl im Spielfeld verändert.',
      version: '2023.3.1',
      date: '2023-08-06',
      status: 'check-square',
    },
  ],
  'release-2021-2': [
    {
      type: 'pencil',
      key: 'SK-25',
      summary: 'Anmeldung jetzt mit E-Mail-Adresse und Passwort möglich',
      version: '2021.2.0',
      date: '2021-03-28',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-26',
      summary: 'Fehler behoben: Textauswahl im Sudoku Feld (bei Notizen und Zahlen) wird jetzt verhindert',
      version: '2021.2.1',
      date: '2021-03-29',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-27',
      summary: 'Wenn mit nur einer Prüfung das Rätsel gelöst wird, wird jetzt „Perfekt gelöst!“ statt „1 mal geprüft“ angezeigt',
      version: '2021.2.2',
      date: '2021-03-29',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-28',
      summary: 'Fehler behoben: Stoppuhr hält jetzt automatisch an, wenn ein Rätsel gelöst wurde',
      version: '2021.2.3',
      date: '2021-03-29',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-29',
      summary: 'Notizen invertieren als Standard auswählen nun per Schaltfläche direkt am Sudoku Rätsel möglich',
      version: '2021.2.4',
      date: '2021-03-29',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-30',
      summary: 'Quellenangabe und Copyright-Hinweise auf Ausdruck verkleinert',
      version: '2021.2.5',
      date: '2021-03-29',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-31',
      summary: 'Animationen entfernt und Performanceoptimierungen durchgeführt',
      version: '2021.2.6',
      date: '2021-03-29',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-32',
      summary: 'FAQ angepasst - YouTube Hilfe-Video zur Neuanmeldung hinzugefügt',
      version: '2021.2.7',
      date: '2021-03-30',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-33',
      summary: 'Fehler behoben: Werbung wurde trotz bezahlter Unterstützung angezeigt - Code refactored',
      version: '2021.2.8',
      date: '2021-04-01',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-34',
      summary: 'Drucken per Schaltfläche in der Rätsel-Symbolleiste',
      version: '2021.2.9',
      date: '2021-04-01',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-35',
      summary: 'Besucherzähler nach oben in den Loginbereich verschoben um Sichtbarkeit zu erhöhen',
      version: '2021.2.10',
      date: '2021-04-02',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-36',
      summary: 'Fehler behoben: Die Schaltfläche „Neues Rätsel“ hat nach einem gelösten nicht fehlerfrei funktioniert',
      version: '2021.2.11',
      date: '2021-04-02',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-37',
      summary: 'Neue Schaltfläche „Nächstes Rätsel“ öffnet ein neues Rätsel der gleichen Schwierigkeitssture',
      version: '2021.2.12',
      date: '2021-04-02',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-38',
      summary: 'Neue Schaltfläche „Eingaben löschen“ setzt ein Rästel auf den anfänglichen Zustand zurück',
      version: '2021.2.13',
      date: '2021-04-02',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-39',
      summary: 'Schaltfläche „Speichern“ animiert',
      version: '2021.2.14',
      date: '2021-04-02',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-40',
      summary: 'Neues Rätsel durch Direktauswahlklick auf Sterne',
      version: '2021.2.15',
      date: '2021-04-02',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-41',
      summary: 'Fehler behoben: Dialog „Herzlichen Glückwunsch“ lässt sich jetzt auch auf kleinen Gerätegrößen schließen',
      version: '2021.2.16',
      date: '2021-04-04',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-42',
      summary: '„Sudoku des Tages“ Gewinner:innenliste zurück bis 2018 anzeigen',
      version: '2021.2.17',
      date: '2021-04-04',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-43',
      summary: 'Schaltfläche „Startseite anzeigen“ anzeigen, wenn Sudoku des Tages gelöst ist',
      version: '2021.2.18',
      date: '2021-04-04',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-44',
      summary: 'Fehler behoben: Element „Sudoku des Tages“ in der oberen Navigation aktiv zeigen',
      version: '2021.2.19',
      date: '2021-04-04',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-45',
      summary: '„E-Mail versendet“ in Gewinner:innenliste anzeigen',
      version: '2021.2.20',
      date: '2021-08-03',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-46',
      summary: 'Fehler behoben: Cachefehler beim Laden des Sudoku des Tages',
      version: '2021.2.21',
      date: '2021-10-21',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-47',
      summary: 'Fehler behoben: PayPal-Zahlungen nicht möglich',
      version: '2021.2.22',
      date: '2021-11-14',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-48',
      summary: 'Black Friday Angebot für Unterstützer:innen',
      version: '2021.2.23',
      date: '2021-11-25',
      status: 'check-square',
    },
  ],
  'release-2021-1': [
    {
      type: 'bug',
      key: 'SK-1',
      summary: 'Fehler behoben: Google Analytics Integration funktioniert nicht',
      version: '2021.1.1',
      date: '2021-03-23',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-2',
      summary: 'Kontaktformular Beschränkungen entfernt',
      version: '2021.1.2',
      date: '2021-03-23',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-3',
      summary: 'Hellerer Hintergrund in den 9x9 Unterquadraten',
      version: '2021.1.3',
      date: '2021-03-23',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-4',
      summary: 'Aktive Felder und Lösungszahlen in Blau geändert',
      version: '2021.1.4',
      date: '2021-03-23',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-5',
      summary: 'Sudoku Grid - Rahmen in Schwarz geändert',
      version: '2021.1.5',
      date: '2021-03-23',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-6',
      summary: 'Fehler behoben: Sudoku des Tages wird deaktiviert, wenn Countdown abgelaufen ist',
      version: '2021.1.6',
      date: '2021-03-23',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-7',
      summary: 'Stoppuhr Millisekunden entfernt',
      version: '2021.1.7',
      date: '2021-03-23',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-8',
      summary: 'Fehler behoben: Bei der Eingabe einer Lösungszahl verhalten sich nicht betroffene Eingaben seltsam',
      version: '2021.1.8',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-9',
      summary: 'Fehler behoben: Sudoku des Tage ist deaktiviert und kann nicht gelöst werden',
      version: '2021.1.9',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-10',
      summary: 'Fehler behoben: Zeilenumbruch bei Dialog Überschrift fehlerhaft',
      version: '2021.1.10',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-11',
      summary: 'Releasehinweise für Änderungen und Bugfixes in der Continous Integration implementiert',
      version: '2021.1.11',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-12',
      summary: 'Cookie Einstellungen geändert',
      version: '2021.1.12',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-13',
      summary: 'Ausdruck ist jetzt platzsparender, so dass 2 Rätsel auf eine Seite gedruckt werden können',
      version: '2021.1.13',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-14',
      summary: 'Fehler behoben: Ausdruck auf dem iPad im Landscape Modus funktioniert jetzt',
      version: '2021.1.14',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-15',
      summary: 'Fehler behoben: Viewport Einstellung korrigiert, so dass Pinch-Zoom möglich ist',
      version: '2021.1.15',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-16',
      summary: 'Fehler behoben: Stoppuhr wird jetzt auf Smartphones angezeigt',
      version: '2021.1.16',
      date: '2021-03-24',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-17',
      summary: 'Verfallszeit für Login PIN auf 2 Stunden erhöht',
      version: '2021.1.17',
      date: '2021-03-25',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-18',
      summary: 'Login PIN besteht nur noch aus Buchstaben',
      version: '2021.1.18',
      date: '2021-03-25',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-19',
      summary: 'Fehler behoben: Cookie Speicherung war teilweise fehlerhaft',
      version: '2021.1.19',
      date: '2021-03-25',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-20',
      summary: 'Ambiente Design nach Tageszeit implementiert',
      version: '2021.1.20',
      date: '2021-03-25',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-21',
      summary: 'Schriftstärke der Notizfelder reduziert um Lesbarkeit zu verbessern',
      version: '2021.1.21',
      date: '2021-03-26',
      status: 'check-square',
    },
    {
      type: 'bug',
      key: 'SK-22',
      summary: 'Fehler behoben: Doppelter Tap auf einem Tablet führ nicht mehr zur Vergrößerung',
      version: '2021.1.22',
      date: '2021-03-26',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-23',
      summary: 'Schnelleingabemodus für schnelleres Eintragen der Notizen bei inaktiven Eingabefeldern implementiert',
      version: '2021.1.23',
      date: '2021-03-26',
      status: 'check-square',
    },
    {
      type: 'pencil',
      key: 'SK-24',
      summary: 'Schnelleingabemodus optimiert, so dass ein Doppelklick jetzt nicht mehr so lange nachhält',
      version: '2021.1.24',
      date: '2021-03-28',
      status: 'check-square',
    },
  ],
}

export { releases }
