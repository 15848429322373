const version = {
  actual: '2025.0.0',
  splashCookie: '2021.2.0',
  path: '/2025-0',
  splashscreen: {
    displayUntil: '2021-04-02',
  },
}

export { version }
